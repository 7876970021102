var _ = Object.defineProperty;
var R = (e, i, n) => i in e ? _(e, i, { enumerable: !0, configurable: !0, writable: !0, value: n }) : e[i] = n;
var t = (e, i, n) => (R(e, typeof i != "symbol" ? i + "" : i, n), n);
var r;
(function(e) {
  e.FREE = "free", e.LAUNCH = "launch", e.PRO = "pro";
})(r || (r = {}));
var a;
(function(e) {
  e.ACTIVE = "active", e.INACTIVE = "inactive";
})(a || (a = {}));
var d;
(function(e) {
  e.InviteMember = "InviteMember", e.RemoveMember = "RemoveMember", e.ViewMembers = "ViewMembers", e.TransferOwnership = "TransferOwnership", e.UpdatePermissions = "UpdatePermissions", e.UpdateSettings = "UpdateSettings", e.UpdateCustomDomain = "UpdateCustomDomain", e.UpdateSubdomain = "UpdateSubdomain", e.ReadSettings = "ReadSettings", e.ReadBilling = "ReadBilling", e.UpdateBilling = "UpdateBilling", e.DeleteSpace = "DeleteSpace", e.CreatePage = "CreatePage", e.EditContent = "EditContent", e.ReadDraft = "ReadDraft", e.ReadPublishedContent = "ReadPublishedContent", e.ReadPageInfo = "ReadPageInfo", e.CreateLock = "CreateLock", e.PromoteToOwner = "PromoteToOwner", e.UpdateMetadata = "UpdateMetadata", e.UpdateCustomization = "UpdateCustomization", e.UpdateAffiliate = "UpdateAffiliate", e.ReadBasicAnalytics = "ReadBasicAnalytics", e.ReadAdvancedAnalytics = "ReadAdvancedAnalytics", e.Collection = "Collection";
})(d || (d = {}));
var o;
(function(e) {
  e.OWNER = "owner", e.ADMIN = "admin", e.WRITER = "writer", e.READER = "reader";
})(o || (o = {}));
var l;
(function(e) {
  e.FORM = "form", e.PAGE = "page", e.SECTION = "section", e.MOBILE_SECTION = "mobile_section", e.BLOG_PAGE = "blog_page";
})(l || (l = {}));
var c;
(function(e) {
  e.AIRTABLE = "airtable", e.NOTION = "notion", e.STRIPE = "stripe";
})(c || (c = {}));
var h;
(function(e) {
  e.MAX_ITEMS = "max_items", e.FILTER_COLUMN = "filter_column", e.FILTER_COLUMN_TYPE = "filter_column_type", e.FILTER_CONDITION = "filter_condition", e.FILTER_VALUE = "filter_value", e.SORT_COLUMN = "sort_column", e.SORT_DIRECTION = "sort_direction", e.SEARCH_COLUMNS = "search_columns", e.SHOW_SEARCH_BAR = "show_search_bar", e.VISITOR_FILTER_COLUMN = "visitor_filter_column", e.VISITOR_FILTER_STYLE = "visitor_filter_style", e.VISITOR_FILTER_COLOR = "visitor_filter_color", e.VISITOR_FILTER_COLUMN_TYPE = "visitor_filter_column_type";
})(h || (h = {}));
var p;
(function(e) {
  e.FILES = "files";
})(p || (p = {}));
function v(e) {
  return e && e.name && e.schema;
}
class g {
  constructor(i) {
    t(this, "id");
    t(this, "deleted_at");
    t(this, "updated_at");
    t(this, "created_at");
    t(this, "space_id");
    t(this, "schemaVersion", 0);
    t(this, "public_permission");
    t(this, "field");
    function n(s) {
      return s && s.name;
    }
    if (i && n(i)) {
      this.id = i.id, this.space_id = i.space_id, this.deleted_at = new Date().toString(), this.updated_at = i.updated_at, this.created_at = new Date().toString(), this.public_permission = 2, this.field = {
        name: i.name,
        schemaVersion: this.schemaVersion,
        schema: i.schema
      };
      return;
    }
    if (i && !n(i)) {
      if (this.id = i.id, this.space_id = i.space_id, this.created_at = i.created_at, this.updated_at = i.updated_at, this.deleted_at = i.deleted_at, this.public_permission = 2, v(i.field))
        this.field = { ...i.field };
      else
        throw Error("Invalid form collection field schema");
      return;
    }
    this.id = "", this.space_id = "", this.deleted_at = new Date().toString(), this.updated_at = new Date().toString(), this.created_at = new Date().toString(), this.public_permission = 2, this.field = { name: "", schemaVersion: this.schemaVersion, schema: {} };
  }
  getFormData() {
    return {
      id: this.id,
      space_id: this.space_id,
      updated_at: this.updated_at || new Date().toString(),
      published_at: new Date().toString(),
      name: this.field.name,
      schemaVersion: this.field.schemaVersion,
      schema: this.field.schema
    };
  }
}
var m;
(function(e) {
  e.DIGITAL_DOWNLOAD = "digital_download";
})(m || (m = {}));
var f;
(function(e) {
  e.HERO = "hero", e.CLIENTS = "clients", e.EVENTS = "events", e.FEATURES = "features", e.PRICING = "pricing", e.CALL_TO_ACTION = "call_to_action", e.FOOTER = "footer", e.SHOP = "shop", e.COLLECTION = "collection";
})(f || (f = {}));
var u;
(function(e) {
  e.HERO = "hero", e.HIGHLIGHTS = "highlights", e.LINKS = "links";
})(u || (u = {}));
export {
  g as FormCollection,
  p as ICollectionColumnType,
  h as ICollectionDataItemMetadataKeys,
  c as ICollectionIntegration,
  u as MOBILE_SECTION_TEMPLATE_CATEGORY,
  m as PRODUCT_TYPE,
  d as SPACE_ACTION,
  o as SPACE_ROLE,
  r as SUBSCRIPTION_PLAN,
  a as SUBSCRIPTION_STATUS,
  f as TEMPLATE_CATEGORY,
  l as TEMPLATE_TYPE
};
