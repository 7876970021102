var _ = Object.defineProperty;
var C = (h, t, n) => t in h ? _(h, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : h[t] = n;
var f = (h, t, n) => (C(h, typeof t != "symbol" ? t + "" : t, n), n);
import { EditorAPI as u } from "../EditorAPI.js";
import { getPermissionAndSubscriptionConfig as l } from "../../utils/getPermissionAndSubscriptionConfig.js";
import { OAUTH_REDIRECT_URI as w } from "../../constants.js";
class z {
  constructor(t) {
    f(this, "api");
    this.api = t;
  }
  async create(t, n, a, e, i) {
    const o = await l(t, e, i), s = {
      name: n,
      space_id: t,
      token: a
    }, c = "/app/create", r = new u(this.api);
    return r.logoutOnUnauthorized = !1, await r.post(c, s, !0, o);
  }
  async connectOAuth(t, n, a, e, i, o) {
    const s = await l(t, i, o), c = {
      app: {
        name: n,
        space_id: t
      },
      req: {
        code: a,
        grant_type: "authorization_code",
        redirect_uri: `${e}${w}`
      }
    }, r = "/app/auth/callback/create", p = new u(this.api);
    return p.logoutOnUnauthorized = !1, await p.post(r, c, !0, s);
  }
  async updateOAuth(t, n, a, e, i, o, s) {
    const c = await l(t, o, s), r = {
      app: {
        id: n,
        name: a,
        space_id: t
      },
      req: {
        code: e,
        grant_type: "authorization_code",
        redirect_uri: `${i}${w}`
      }
    }, p = "/app/auth/callback/update", d = new u(this.api);
    return d.logoutOnUnauthorized = !1, await d.post(p, r, !0, c);
  }
  async update(t, n, a, e, i, o) {
    const s = await l(t, i, o), c = {
      id: n,
      name: a,
      space_id: t,
      token: e
    }, r = "/app/update", p = new u(this.api);
    return p.logoutOnUnauthorized = !1, await p.put(r, c, !0, s);
  }
  async delete(t, n, a, e, i) {
    const o = await l(t, e, i), s = {
      id: n,
      name: a,
      space_id: t
    }, c = "/app/delete", r = new u(this.api);
    return r.logoutOnUnauthorized = !1, await r.delete(c, s, !0, o);
  }
  async list(t, n, a) {
    const e = await l(t, n, a), i = `/app/list/${t}`, o = new u(this.api);
    return o.logoutOnUnauthorized = !1, await o.get(i, !0, e);
  }
  async connectCollection(t, n, a, e) {
    const i = await l(t, a, e), o = `/app/connect_collection/${t}`, s = new u(this.api);
    s.logoutOnUnauthorized = !1;
    const c = await s.post(o, n, !0, i);
    return c == null ? void 0 : c.data;
  }
  async updateCollectionContent(t, n, a, e, i) {
    const o = new u(this.api);
    o.logoutOnUnauthorized = !1;
    const s = await l(t, e, i), c = `/app/collection_content/${t}/${n}`;
    return await o.put(c, a, !0, s);
  }
  getCollectionContentURLPrefix() {
    return `${this.api}/app/collection_content/`;
  }
  getCollectionContentURL(t) {
    return `${this.getCollectionContentURLPrefix()}${t}`;
  }
  async publishCollectionContent(t, n, a, e, i, o) {
    const s = this.getCollectionContentURLPrefix();
    if (!a.includes(s))
      throw new Error("Draft content URL is not from Typedream's collection_content url");
    const c = a.replace(s, ""), r = new u(this.api);
    r.logoutOnUnauthorized = !1;
    const p = await l(t, i, o), d = `/app/publish_collection_content/${t}/${n}/${c}`;
    return await r.put(d, e, !0, p);
  }
  async getAuthorizationCode(t, n, a, e) {
    const i = await l(n, a, e), o = "/app/oauth/code";
    return await new u(this.api).post(o, t, !0, i);
  }
}
export {
  z as AppAPI
};
