import '../styles/bulma.scss';
import '../styles/globals.css';
import '@typedream/components/dist/style.css';
import type { AppProps } from 'next/app';
import mixpanel from 'mixpanel-browser';
import { API } from '@typedream/data';
import {
  EDITOR_API_BASE_URL, MIX_PANEL_KEY, PORTAL_ID, WARNING_PORTAL_ID,
} from '../constants';
import { Notification } from '../components/Notification';

mixpanel.init(MIX_PANEL_KEY);
API.init(EDITOR_API_BASE_URL as string);

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Component {...pageProps} />
      <div id={PORTAL_ID} />
      <div id={WARNING_PORTAL_ID} />
      <Notification />
    </>
  );
}

export default MyApp;
