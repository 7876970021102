import {
  ButtonElement, ColumnElement, ColumnItemElement, DreamBlock, ImageElement,
} from '@typedream/core';

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> =
Pick<T, Exclude<keyof T, Keys>>
& {
  [K in Keys]-?:
  Required<Pick<T, K>>
  & Partial<Record<Exclude<Keys, K>, undefined>>
}[Keys];

export enum SUBSCRIPTION_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface ISubscriptionMetadata {
  'affiliate': boolean,
  'analytics_activated': boolean,
  'read_advanced_analytics': boolean,
  'analytics_timezone': string,
  'subscription': {
    'cycle': 'month' | 'year',
    'price': number,
    'price_1ILQxNKqrvH8BszmLfET99Mw'?: string,
    'price_1ILR09KqrvH8BszmA71uyAOx'?: string,
  },
  'susbcription_email': string,
  'subscription_status': 'active' | 'inactive',
  'current_period_start': number,
  'current_period_end': number,
  'trial_start': number,
  'trial_end': number,
  'subscription_created': number,
  'subscription_start_date': number,
  'cancel_at': number,
  'cancel_at_period_end': boolean,
}
export interface ISubscription {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  space_id: string;
  product_id: string;
  price_id: string;
  status: SUBSCRIPTION_STATUS;
  metadata: ISubscriptionMetadata;
  customer_id: string;
  subscription_id: string;
}

export interface ISpaceCustomization {
  theme?: 'dark' | 'light',
  enable_theme_toggle?: boolean,
  header_code?: string,
  body_code?: string,
  transclusion_reference?: {},
  collection_item_data?: {},
  redirect_custom_domain?: string,
}

export interface ISpaceRoute {
  page_id: string;
  children_template_page_id?: string;
  children_keys?: string[];
  children?: { [slug: string]: ISpaceRoute };
}

export interface ISpaceMetadata {
  metatags: {
    title: string;
    description: string;
  },
  twitter: {
    image: string;
    title: string;
    description: string;
    username: string;
  },
  facebook: {
    image: string;
    title: string;
    description: string;
  },
}

export type IPageMetadata = ISpaceMetadata;
export interface IPageCustomization extends ISpaceCustomization {
  collection_data?: {}
  item_page_data?: {}
}

export interface IPage {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  name: string;
  content: DreamBlock;
  content_updated_at: string;
  published_content: DreamBlock;
  published_at: string;
  customization: IPageCustomization;
  metadata: IPageMetadata;
  public: boolean;
  allow_duplicate: boolean;
  space_id: string;
  locks: [];
}
export interface IUser {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  name: string;
  email: string;
  photo: string;
  metadata: {
    hear_from: string;
  };
  source: string;
  cotter_id: string;
  last_auth: string;
}

export enum SPACE_ROLE {
  OWNER = 'owner',
  ADMIN = 'admin',
  WRITER = 'writer',
  READER = 'reader',
}

export enum SPACE_ACTION {
  PromoteToOwner = 'PromoteToOwner',
  InviteMember = 'InviteMember',
  RemoveMember = 'RemoveMember',
  ViewMembers = 'ViewMembers',
  TransferOwnership = 'TransferOwnership',
  UpdatePermissions = 'UpdatePermissions',
  ReadSettings = 'ReadSettings',
  UpdateSettings = 'UpdateSettings',
  UpdateCustomDomain = 'UpdateCustomDomain',
  UpdateMetadata = 'UpdateMetadata',
  UpdateCustomization = 'UpdateCustomization',
  UpdateSubdomain = 'UpdateSubdomain',
  DeleteSpace = 'DeleteSpace',
  CreatePage = 'CreatePage',
  EditContent = 'EditContent',
  ReadDraft = 'ReadDraft',
  ReadPublishedContent = 'ReadPublishedContent',
  ReadPageInfo = 'ReadPageInfo',
  UpdateBilling = 'UpdateBilling',
  ReadBilling = 'ReadBilling',
  UpdateAffiliate = 'UpdateAffiliate',
  ReadBasicAnalytics = 'ReadBasicAnalytics',
  ReadAdvancedAnalytics = 'ReadAdvancedAnalytics',
  Collection = 'Collection',
}

export enum SUBSCRIPTION_PLAN {
  FREE = 'free',
  LAUNCH = 'launch',
  PRO = 'pro',
}

export enum EVENT {
  SIGN_UP = 'mobile_sign_up',
  LOGIN = 'mobile_login',
  PUBLISH = 'mobile_publish',
}

export type ColumnElementBlock = Extract<DreamBlock, { type: ColumnElement['type'] }>;
export type ColumnItemWithButtonChildren = Extract<DreamBlock, { type: ColumnItemElement['type'] }> & { children: Extract<DreamBlock, { type: ButtonElement['type'] }>[] };

export type ColumnWithColumnItemButtonChildren = ColumnElementBlock
& { children: ColumnItemWithButtonChildren[] };
export type ButtonBlock = Extract<DreamBlock, { type: ButtonElement['type'] }>;

export type ImageBlock = Extract<DreamBlock, { type: ImageElement['type'] }>;
export type SocialIconButtonBlock = Omit<ButtonBlock, 'geerated_as'> & {
  generated_as:
  'social_icon_instagram' |
  'social_icon_twitter' |
  'social_icon_facebook' |
  'social_icon_youtube' |
  'social_icon_linkedin' |
  'social_icon_tiktok' |
  'social_icon_website' |
  'social_icon_email'
};
export enum EDITOR_ID {
  PAGE = 'page',
  PREVIEW = 'preview',
  EDITABLE_PREVIEW = 'editable_preview',
}

export type ColumnListsItemTypes = 'list_item_background'
| 'list_item_image'
| 'list_item_button'
| 'list_item_title'
| 'list_item_subtitle';

export declare type ColumnListsEditPropertyTypes<T> = T extends 'list_item_background' ? 'background' | 'link'
  : T extends 'list_item_image' ? 'image' | 'link'
    : T extends 'list_item_button' ? 'icon' | 'text' | 'link' | 'background' | 'textColor' | 'iconColor'
      : T extends 'list_item_title' ? 'text'
        : T extends 'list_item_subtitle' ? 'text'
          :never;
export type ColumnListsEditPropertyTypesUnion = ColumnListsEditPropertyTypes<ColumnListsItemTypes>;
