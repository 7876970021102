var n = Object.defineProperty;
var p = (s, t, e) => t in s ? n(s, t, { enumerable: !0, configurable: !0, writable: !0, value: e }) : s[t] = e;
var r = (s, t, e) => (p(s, typeof t != "symbol" ? t + "" : t, e), e);
import { EditorAPI as i } from "../EditorAPI.js";
class l {
  constructor(t) {
    r(this, "api");
    this.api = t;
  }
  login(t, e) {
    let a = "/user/login";
    return e && (a = `${a}?check_only=true`), new i(this.api).post(a, t, !1);
  }
  signup(t, e) {
    let a = "/user/signup";
    return e && (a = `${a}?check_only=true`), new i(this.api).post(a, t, !1);
  }
  verify(t) {
    const e = "/user/verify?create_site=false", a = new i(this.api);
    return a.logoutOnUnauthorized = !1, a.post(e, t, !1);
  }
  verifyGoogle(t) {
    const e = "/user/verify/google?create_site=false", a = new i(this.api);
    return a.logoutOnUnauthorized = !1, a.post(e, t, !1);
  }
  async update(t) {
    const e = {
      metadata: t.metadata
    }, a = "/user/update";
    return new i(this.api).put(a, e);
  }
}
export {
  l as UserAPI
};
