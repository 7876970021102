var p = Object.defineProperty;
var g = (a, t, i) => t in a ? p(a, t, { enumerable: !0, configurable: !0, writable: !0, value: i }) : a[t] = i;
var e = (a, t, i) => (g(a, typeof t != "symbol" ? t + "" : t, i), i);
import { getSpacePermissionConfig as m } from "../../utils/getSpacePermissionConfig.js";
import { getPermissionAndSubscriptionConfig as f } from "../../utils/getPermissionAndSubscriptionConfig.js";
import { EditorAPI as c } from "../EditorAPI.js";
class y {
  constructor(t) {
    e(this, "api");
    this.api = t;
  }
  async createSite(t, i, s, n) {
    const r = await f(t, s, n), o = `/analytics/create_site/${t}`;
    return await new c(this.api).post(o, i, !0, r);
  }
  async getSharedLink(t, i) {
    const s = await m(t, i), n = `/analytics/shared_link/${t}`;
    return await new c(this.api).get(n, !0, s);
  }
}
export {
  y as PlausibleAPI
};
