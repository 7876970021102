var h = Object.defineProperty;
var m = (s, t, a) => t in s ? h(s, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : s[t] = a;
var u = (s, t, a) => (m(s, typeof t != "symbol" ? t + "" : t, a), a);
import { getSpacePermissionConfig as o } from "../../utils/getSpacePermissionConfig.js";
import { EditorAPI as p } from "../EditorAPI.js";
class y {
  constructor(t) {
    u(this, "api");
    this.api = t;
  }
  async list(t, a, i, n) {
    const e = new URLSearchParams("");
    a && e.append("space_only", a.toString()), n && e.append("category", n);
    const c = new p(this.api);
    let r = "/template/list";
    r = `${r}?${e.toString()}`;
    const l = await o(t, i);
    return c.get(r, !0, l);
  }
  async listPublic(t, a) {
    const i = new URLSearchParams("");
    t && i.append("category", t), a && i.append("type", a);
    const n = new p(this.api);
    let e = "/template/list/public";
    return e = `${e}?${i.toString()}`, n.get(e, !0);
  }
  async get(t, a, i) {
    const n = await o(t, i), e = `/template/${a}`;
    return new p(this.api).get(e, !0, n);
  }
  async use(t, a, i) {
    const n = await o(t, i), e = `/template/use/${a}`;
    return new p(this.api).get(e, !0, n);
  }
  async create(t, a, i) {
    const n = await o(t, i), e = "/template";
    return new p(this.api).post(e, a, !0, n);
  }
  async update(t, a, i, n) {
    const e = await o(t, n), c = `/template/${a}`;
    return new p(this.api).put(c, i, !0, e);
  }
  async listSection() {
    const t = "/template/section";
    return await new p(this.api).get(t, !1);
  }
}
export {
  y as TemplateAPI
};
