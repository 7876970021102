import { AlignType, ContentElement } from '@typedream/core';
import { SPACE_ACTION, SPACE_ROLE, SUBSCRIPTION_PLAN } from './types';

export const PORTAL_ID = 'td-portal';
export const WARNING_PORTAL_ID = 'td-warning-portal';
export const GOOGLE_CLIENT_ID = '226080243779-hcsitg1umgucc3rdcj43k4skr572d93d.apps.googleusercontent.com';
export const MIX_PANEL_KEY = 'aafb8cfde053fc023ac5926bda69211b';
export const EDITOR_API_BASE_URL = process.env.NEXT_PUBLIC_EDITOR_API_BASE_URL;

export const RENDERER_DOMAIN = process.env.NEXT_PUBLIC_RENDERER_DOMAIN;
export const SUBSCRIPTION_STORAGE_KEY = 'sb';
export const LOGO_NAME = 'Typedream Links';
export const SOURCE_STORAGE_KEY = 'utm_source';
export const PROMO_SOURCE_STORAGE_KEY = 'promo_utm_source';
export const PROMO_SOURCE_STORAGE_EXPIRY = 1000 * 24 * 60 * 60 * 2; // Promo is saved for 2 days
export const USER_STORAGE_KEY = 'user';
export const SPACE_STORAGE_KEY = 'spaces';
export const SPACE_PERMISSION_STORAGE_KEY = 'space_permissions';
export const DEFAULT_SPACE_STORAGE_KEY = 'default_space';
export const SPACE_PERMISSION_HEADER_KEY = 'Space-Permission';
export const SUBSCRIPTION_HEADER_KEY = 'Subscription';
export const CUSTOM_DOMAIN_POPUP_STORAGE_KEY = 'custom_domain_popup_expiry';
export const CHROME_WARNING_POPUP_HIDE_STORAGE_KEY = 'chrome_warning_popup_hide_expiry';
export const PLACEHOLDER_IMAGE = '/images/image-placeholder.svg';

export const TERMS_OF_SERVICE_LINK = 'https://typedream.com/terms';
export const PRIVACY_POLICY_LINK = 'https://typedream.com/privacy';
export const MAX_UNDO_STEPS = 10;

// from @udecode/plate-basic-marks
export const MARK_BOLD = 'bold';
export const MARK_ITALIC = 'italic';
export const MARK_UNDERLINE = 'underline';

export const SPACE_PERMISSIONS = {
  [SPACE_ACTION.InviteMember]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.RemoveMember]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.ViewMembers]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.TransferOwnership]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.UpdatePermissions]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.UpdateSettings]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.UpdateCustomDomain]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.UpdateSubdomain]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.ReadSettings]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: true,
    [SPACE_ROLE.READER]: true,
  },
  [SPACE_ACTION.ReadBilling]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.UpdateBilling]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.DeleteSpace]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: false,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.CreatePage]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: true,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.EditContent]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: true,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.ReadDraft]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: true,
    [SPACE_ROLE.READER]: false,
  },
  [SPACE_ACTION.ReadPublishedContent]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: true,
    [SPACE_ROLE.READER]: true,
  },
  [SPACE_ACTION.ReadPageInfo]: {
    [SPACE_ROLE.OWNER]: true,
    [SPACE_ROLE.ADMIN]: true,
    [SPACE_ROLE.WRITER]: true,
    [SPACE_ROLE.READER]: true,
  },
};

export const SPACE_PLAN_FREE_PRODUCT_ID = 'FREE';
export const SPACE_PLAN_LAUNCH_PRODUCT_ID = process.env
  .NEXT_PUBLIC_SPACE_PLAN_LAUNCH_PRODUCT_ID as string;
export const SPACE_PLAN_PRO_PRODUCT_ID = process.env
  .NEXT_PUBLIC_SPACE_PLAN_PRO_PRODUCT_ID as string;

export const SPACE_SUBSCRIPTIONS = {
  [SPACE_PLAN_LAUNCH_PRODUCT_ID]: SUBSCRIPTION_PLAN.LAUNCH,
  [SPACE_PLAN_FREE_PRODUCT_ID]: SUBSCRIPTION_PLAN.FREE,
  [SPACE_PLAN_PRO_PRODUCT_ID]: SUBSCRIPTION_PLAN.PRO,
};

export const MAX_FILE_SIZE = (10 ** 6) * 4.5;
export const SOCIAL_ICON_NAME = {
  social_icon_facebook: 'Facebook',
  social_icon_twitter: 'Twitter',
  social_icon_instagram: 'Instagram',
  social_icon_youtube: 'Youtube',
  social_icon_linkedin: 'LinkedIn',
  social_icon_tiktok: 'TikTok',
  social_icon_email: 'Email',
  social_icon_website: 'Website',
};

export const SOCIAL_ICON_LINK_LABEL = {
  social_icon_facebook: 'Facebook Username',
  social_icon_twitter: 'Twitter Username',
  social_icon_instagram: 'Instagram Username',
  social_icon_youtube: 'Youtube Username',
  social_icon_linkedin: 'LinkedIn Username',
  social_icon_tiktok: 'TikTok Username',
  social_icon_email: 'Email Address',
  social_icon_website: 'Website URL',
};
export const SOCIAL_ICON_LINK_PLACEHOLDER = {
  social_icon_facebook: 'username',
  social_icon_twitter: 'username',
  social_icon_instagram: 'username',
  social_icon_youtube: 'username',
  social_icon_linkedin: 'username',
  social_icon_tiktok: 'username',
  social_icon_email: 'username@mail.com',
  social_icon_website: 'domain.com',
};

export const SOCIAL_ICON_COLORS: {
  [key: string]: string;
} = {
  social_icon_facebook: 'blue',
  social_icon_twitter: 'blue',
  social_icon_instagram: 'pink',
  social_icon_youtube: 'red',
  social_icon_linkedin: 'blue',
  social_icon_tiktok: 'black',
  social_icon_email: 'red',
  social_icon_website: 'blue',
};

export const SOCIAL_ICON_LOGO: {
  [key: string]: {
    name: string,
    type: 'regular' | 'logo' | 'solid'
  };
} = {
  social_icon_facebook: { name: 'facebook', type: 'logo' },
  social_icon_twitter: { name: 'twitter', type: 'logo' },
  social_icon_instagram: { name: 'instagram-alt', type: 'logo' },
  social_icon_youtube: { name: 'youtube', type: 'logo' },
  social_icon_linkedin: { name: 'linkedin', type: 'logo' },
  social_icon_tiktok: { name: 'tiktok', type: 'logo' },
  social_icon_email: { name: 'envelope', type: 'solid' },
  social_icon_website: { name: 'window-alt', type: 'solid' },
};

export const DEFAULT_ALIGN_TYPE: AlignType = 'align_left';
export const ELEMENT_DEFAULT_ALIGN_TYPE: { [Property in ContentElement['type']]?: AlignType } = {
  img: 'align_center',
};
